
































import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GET_DETAILS } from '../store/getters';
import { FETCH_DETAILS } from '../store/actions';
import { ApiResponse } from '@/components/types';
import { ConservatorshipDetails, ConservatorshipList, FetchDetails } from '../types';
import { FETCH_CONTACT_PERSONS } from '@/modules/conservatorshipToContact/store';
import { routeToLocation } from '@/router';
import { AuthorizationMixin } from '@/views/mixin';

const ConservatorshipTab = () => import('../components/details/tabs/ConservatorshipTab.vue');
const FinanceAccountTab = () => import('../components/details/tabs/FinanceAccountTab.vue');
const MedicalDataTab = () => import('../components/details/tabs/MedicalDataTab.vue');
const ContactTab = () => import('../components/details/tabs/ContactTab.vue');
const EventTab = () => import('../components/details/tabs/EventTab.vue');
const LetterTab = () => import('../components/details/tabs/LetterTab.vue');
const NotesTab = () => import('../components/details/tabs/NotesTab.vue');
const HistoryTab = () => import('../components/details/tabs/HistoryTab.vue');
const ReportTab = () => import('../components/details/tabs/ReportTab.vue');
const FileManagementTab = () => import('../components/details/tabs/FileManagementTab.vue');

const Conservatorship = namespace('conservatorship');
const ConservatorshipToContact = namespace('conservatorshipToContact');

@Component({
  components: {
    HistoryTab,
    LetterTab,
    EventTab,
    ContactTab,
    MedicalDataTab,
    ConservatorshipTab,
    ReportTab,
    FinanceAccountTab,
    FileManagementTab,
    NotesTab
  }
})
export default class Details extends AuthorizationMixin {
  @Conservatorship.State('list') list!: ConservatorshipList;
  @Conservatorship.Action(FETCH_DETAILS) fetchDetails!: (params: FetchDetails) => Promise<ApiResponse>;
  @Conservatorship.Getter(GET_DETAILS) getDetails!: (id: string) => ConservatorshipDetails | undefined;

  @ConservatorshipToContact.Action(FETCH_CONTACT_PERSONS) fetchContactPersons!: (conservatorship_id: string) => Promise<ApiResponse>;

  tab: string = '';

  get tabs(): Array<{ label: string, icon: string, name: string, component: string, condition?: boolean }> {
    return [
      {
        label: 'label.conservatorshipData',
        icon: 'assignment',
        name: 'tab-conservatorship',
        component: 'conservatorship-tab'
      },
      {
        label: 'label.financeAccount.title',
        icon: 'monetization_on',
        name: 'tab-finance-account',
        component: 'finance-account-tab',
        condition: this.conservatorship && this.conservatorship.hasFinancialAdministration
      },
      { label: 'label.medicalData', icon: 'healing', name: 'tab-medical-data', component: 'medical-data-tab' },
      { label: 'label.contactsTab', icon: 'contacts', name: 'tab-contacts', component: 'contact-tab' },
      {
        label: 'label.appointmentsAndTerms',
        icon: 'event',
        name: 'tab-events',
        component: 'event-tab',
        condition: this.$vuetify.breakpoint.mdAndUp && (this.isConservator() || this.isAdmin())
      },
      {
        label: 'label.correspondence',
        icon: 'email',
        name: 'tab-letters',
        component: 'letter-tab',
        condition: this.$vuetify.breakpoint.mdAndUp && (this.isConservator() || this.isSupport())
      },
      {
        label: 'common.reports',
        icon: 'description',
        name: 'tab-reports',
        component: 'report-tab',
        condition: this.$vuetify.breakpoint.mdAndUp
      },
      {
        label: 'common.fileManagement',
        icon: 'folder_shared',
        name: 'tab-files',
        component: 'file-management-tab',
        condition: this.isConservator() || this.isSupport()
      },
      {
        label: 'common.notes',
        icon: 'speaker_notes',
        name: 'tab-notes',
        component: 'notes-tab',
        condition: this.isConservator() || this.isSupport()
      },
      {
        label: 'common.history',
        icon: 'history',
        name: 'tab-history',
        component: 'history-tab',
        condition: this.$vuetify.breakpoint.mdAndUp && (this.isConservator() || this.isSupport())
      }
    ].filter(tab => tab.condition === undefined ? true : tab.condition);
  }

  get conservatorship() {
    return this.getDetails(this.$route.params['id']);
  }

  get active(): string {
    return this.$route.query['active'] as string || 'tab-conservatorship';
  }

  set active(active: string) {
    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, active } })).catch(() => {});
  }

  @Watch('active', { immediate: true })
  onActive(active: string) {
    setTimeout(() => this.tab = active, 100);
  }

  @Watch('tab', { immediate: true })
  onTab(tab: string) {
    if (tab === this.active || !tab) return;

    setTimeout(() => this.active = tab, 0);
  }

  @Watch('$route.params.id', { immediate: true })
  async onIdChange(id: string) {
    const { error } = await this.fetchDetails({ id });

    if (error) {
      this.$router.push({ name: 'conservatorship-list' }).catch(() => {});
      return;
    }

    const { error: cpError } = await this.fetchContactPersons(id);

    if (!cpError) return;

    // return this.$router.push({ name: 'conservatorship-list' }).catch(() => {});
  }
}
